import logo200Image from 'assets/img/arteris/sollus_login.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Modal, Spinner } from 'react-bootstrap';
import { Col, Row, } from 'reactstrap';
import {
  Route,
  Redirect
} from "react-router-dom";
import { MdWarning, MdCancel, MdPortableWifiOff } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import { setEquipaments, setUser } from '../store/actions';
import store from '../store';
import axios from 'axios';

import { login } from '../services/auth';
import api from '../services/api';
import { getToken, logout } from "../services/auth";

class AuthForm extends React.Component {

  constructor(props) {
    super(props);

    if (getToken())
      this.state = {
        login: true,
        redirect: false,
        username: "",
        password: "",
      }
    else
      this.state = {
        login: false,
        redirect: false,
        username: "",
        password: "",
      }
  }


  handleLogin = (event) => {
    event.preventDefault();

    api.post('/login', { username: this.state.username, password: this.state.password }, { timeout: 10000 }).then((response) => {
      login(response.data.token);
      this.setState({ login: true });
    }).catch((error) => {
      console.log(error);
      if (error) {
        this.notificationSystem.addNotification({
          title: <MdPortableWifiOff />,
          message: 'Problemas ao conectar com servidor!',
          level: 'error',
        });
      } else if (error.response.status === 401) {
        this.notificationSystem.addNotification({
          title: <MdWarning />,
          message: 'Usuário ou senha inválidos!',
          level: 'error',
        });
      } else {
        this.notificationSystem.addNotification({
          title: <MdCancel />,
          message: 'Erro na requisição!',
          level: 'error',
        });
      }
    })
  }

  loadInitData = () => {
    axios.all([
      api.get('/usuario/myself', { timeout:10000 }),
      api.get('/equipamento/all', { timeout: 10000 })
    ]).then(axios.spread((responseUser, responseEquip) => {
      store.dispatch(setEquipaments(responseEquip.data));
      store.dispatch(setUser(responseUser.data));

      this.setState({ redirect: true });
    })).catch((error) => {
      logout();
      this.setState({ login: false });
      if (error.response === null) {
        this.notificationSystem.addNotification({
          title: <MdCancel />,
          message: 'Problemas ao conectar com servidor!',
          level: 'error',
        });
      }
    });
  }

  render() {
    const { showLogo, usernameLabel, usernameInputProps, passwordLabel, passwordInputProps, children, onLogoClick,
    } = this.props;

    if (this.state.redirect) {
      return<Route><Redirect to='/home' /></Route>
    }

    if (this.state.login) {
      return (
        <div>
          <Modal show={this.state.login} aria-labelledby="contained-modal-title-vcenter" centered onShow={this.loadInitData}>
            <Modal.Body>
              <Row>
                <Col lg={9} md={9} sm={9} xs={9}>
                  <h2>Carregando...</h2>
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <Spinner animation="border" role="status" />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      return (
        <>
          <Form onSubmit={this.handleLogin}>
            {showLogo && (
              <div className="text-center pb-4">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: '100%', cursor: 'pointer' }}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </div>
            )}
            <FormGroup>
              <Label for={usernameLabel}>{usernameLabel}</Label>
              <Input {...usernameInputProps} onChange={
                (event => (this.setState({ username: event.target.value })
                ))} />
            </FormGroup>
            <FormGroup>
              <Label for={passwordLabel}>{passwordLabel}</Label>
              <Input {...passwordInputProps} onChange={
                (event => (this.setState({ password: event.target.value })
                ))} />
            </FormGroup>

            <hr />
            <Button size="lg" className="" block style={{ color: "black" }} color="primary">  Login </Button>
            {children}
          </Form>

          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </>
      );
    }
  }
}

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  showLogo: true,
  usernameLabel: 'Usuário',
  usernameInputProps: {
    type: 'text',
    placeholder: 'usuario',
  },
  passwordLabel: 'Senha',
  passwordInputProps: {
    type: 'password',
    placeholder: 'senha',
  },
  onLogoClick: () => { },
};

export default AuthForm;
