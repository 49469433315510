import React from 'react';
import PropTypes from 'utils/propTypes';
import { useHistory } from 'react-router-dom';
import bn from 'utils/bemnames';
import { FaArrowLeft } from 'react-icons/fa';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Typography from './Typography';

const bem = bn.create('page');


const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  ...restProps
}) => {
  const classes = bem.b('px-3', className);

  return (
    <Tag className={classes} {...restProps}>

      <div className={bem.e('header')}>

        {title !== 'Home' && title !== 'Relatórios' && title !== 'Equipamento' && title !== 'Usuário' &&
          <FaArrowLeft style={{ height: 80, width: 30, marginRight: 20}} onClick={useHistory().goBack}/>
        }
        {title && typeof title === 'string' ? (
          <Typography type="h1" className={bem.e('title')}>
            {title}
          </Typography>

        ) : (
            title
          )}

        {breadcrumbs && (
          <Breadcrumb className={bem.e('breadcrumb')} style={{ backgroudColor: '#FFF' }}>
            <BreadcrumbItem>Home</BreadcrumbItem>
            {breadcrumbs.length &&
              breadcrumbs.map(({ name, active }, index) => (
                <BreadcrumbItem key={index} active={active}>
                  {name}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        )}

      </div>

      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};

export default Page;
