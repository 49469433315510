import React from 'react';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import UsuarioView from './pages/usuario/UsuarioView';
import UsuarioUpdate from './pages/usuario/UsuarioUpdate';
import RelatorioListPage from './pages/relatorio/RelatorioListPage';

const HomePage = React.lazy(() => import('pages/HomePage'));

const EquipamentListPage = React.lazy(() => import('pages/Equipament/EquipamentListPage'));
const EquipamentView = React.lazy(() => import('pages/Equipament/EquipamentView'));
const EquipamentCreate = React.lazy(() => import('pages/Equipament/EquipamentCreate'));
const EquipamentUpdate = React.lazy(() => import('pages/Equipament/EquipamentUpdate'));

const UsuarioCreate = React.lazy(() => import('pages/usuario/UsuarioCreate'));

const PortViewPage = React.lazy(() => import('pages/porta/PortViewPage'));
const PortUpdate = React.lazy(() => import('pages/porta/PortUpdate'));

const UsuarioListPage = React.lazy(() => import('pages/usuario/UsuarioListPage'));

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const isAuthenticated = () => localStorage.getItem("TOKEN_KEY") !== null;

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest}

        render={props => isAuthenticated() ?
            (<Component {...props} />) :
            (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)
        }
    />
);

class Routes extends React.Component {
    render() {
        return (
            <BrowserRouter basename={getBasename()}>
                <Switch>
                    <LayoutRoute
                        exact
                        path="/login"
                        layout={EmptyLayout}
                        component={props => (
                            <AuthPage {...props} />
                        )}
                    />
                    <LayoutRoute
                        exact
                        path="/"
                        layout={EmptyLayout}
                        component={props => (
                            <AuthPage {...props} />
                        )}
                    />

                    <MainLayout breakpoint={this.props.breakpoint}>
                        <React.Suspense fallback={<PageSpinner />}>
                            <PrivateRoute exact path="/home" component={HomePage} />
                            <PrivateRoute exact path="/equipament" component={EquipamentListPage} />
                            <PrivateRoute exact path="/equipament/update/:id" component={EquipamentUpdate} />
                            <PrivateRoute exact path="/equipament/create" component={EquipamentCreate} />
                            <PrivateRoute exact path="/equipament/view/:id" component={EquipamentView} />

                            <PrivateRoute exact path="/port/update" component={PortUpdate} />
                            <PrivateRoute exact path="/port/view/:id" component={PortViewPage} />

                            <PrivateRoute exact path="/user" component={UsuarioListPage} />
                            <PrivateRoute exact path="/user/create" component={UsuarioCreate} />
                            <PrivateRoute exact path="/user/view" component={UsuarioView} />
                            <PrivateRoute exact path="/user/update" component={UsuarioUpdate} />

                            <PrivateRoute exact path="/report" component={RelatorioListPage} />

                        </React.Suspense>
                    </MainLayout>
                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routes;