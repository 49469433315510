import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

//import { MdCancel } from 'react-icons/md';
import { setEquipaments, setUser } from '../../store/actions';
import store from '../../store';
import axios from 'axios';

import api from '../../services/api';
import { logout } from "../../services/auth";

class MainLayout extends React.Component {

  state = {
    redirectToLogin: false,
  }

  //=============================================================
  //=============================================================
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');
      case 'lg':
      case 'xl':
        return this.openSidebar('open');
      default:
        return this.openSidebar('close');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  //=============================================================
  //=============================================================
  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
    this.verifyStore();
  }

  componentDidUpdate() {
    this.checkBreakpoint(this.props.breakpoint);
    this.verifyStore();
  }

  redirectToLogin() {
    if (this.state.redirectToLogin)
      return <Redirect to="/login" />
  }

  verifyStore = () => {
    if (Object.keys(this.props.user).length === 0) {
      //console.log("Busca usuario equipamento do banco");
      axios.all([
        api.get('/usuario/myself', { timeout: 20000 }),
        api.get('/equipamento/all', { timeout: 20000 })
      ]).then(axios.spread((responseUser, responseEquip) => {

        store.dispatch(setEquipaments(responseEquip.data));
        store.dispatch(setUser(responseUser.data));

      })).catch((error) => {
        logout();
        this.setState({ redirectToLogin: true });
        //if (error.response === null) {
        //  this.notificationSystem.addNotification({
        //    title: <MdCancel />,
        //    message: 'Problemas ao conectar com servidor!',
        //    level: 'error',
        //  });
        //} else {
          console.log(error);
        //}
      });
    }
  }
  //=============================================================
  //=============================================================
  render() {
    const { children } = this.props;
    return (
      <main className="cr-app" style={{ backgroundColor: '#FFF' }}>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />

        {this.redirectToLogin()}
        
        <Sidebar />
        <Content fluid onClick={this.handleContentClick}>
          <Header />
          {children}
          <Footer />
        </Content>
      </main>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.stateGlobal.user,
    equipaments: state.stateGlobal.equipaments
  }
}


export default connect(mapStateToProps)(MainLayout);
