
const INITIAL_STATE = {
    user: {},
    equipaments: [    ]
};



export default function stateGlobal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_EQUIPAMENTO':
            return {
                ...state,
                equipaments: action.equipaments,
            };
        case 'SET_USUARIO':
            return {
                ...state,
                user: action.user,
            };
        default:
            return state;
    }
}

/*     Usuario 
 {
        "idUsuario": 1,
        "nomeUsuario": "Software",
        "loginUsuario": "ativa",
        "senhaUsuario": "$2a$10$qXE1.U3vm7QvjEbsOt7otuIFXaXTKypuUqg11uTJNDLkp0/kTgdXq",
        "nivelUsuario": 3,
        "emailUsuario": "software2@ativasolucoes.com.br",
        "telefoneUsuario": "35999056951",
        "usuarioHabilitado": true,
        "receberEmailUsuario": true,
        "receberSMSUsuario": false,
        "observacaoUsuario": null
    },


    Equipamento
{
    codigoEquipamento: "123AT456"
    equipamentoHabilitado: true
    firmwareEquipamento: "145F009-01A"
    habilitaAlarme: false
    idEquipamento: 1
    intervaloLeitura: 60
    ipConexao: ""
    localizacao: {x: -22.253462, y: -45.700658}
    macEquipamento: "D8:80:39:85:F5:3D"
    modoConexao: 1
    nomeEquipamento: "Marthe Ethernet"
    observacao: null
    porcentagemVazio: 0
    portaConexao: 30006
    rangeSensor: 0
    tempoAlarmeDesconexao: 5
    tempoBombaLigada: 0
    tempoPartidaBomba: 0
}
        PORTA
{
        "idPorta": 41,
        "nomePorta": "Volume Reservatório",
        "codigoPorta": null,
        "habilitaPorta": true,
        "tipoPorta": 0,
        "numIO": 0,
        "fatorPorta": 1.0,
        "offsetPorta": 0.0,
        "limiteInferiorPorta": 0.0,
        "limiteSuperiorPorta": 100.0,
        "habilitaAlarmePorta": false,
        "inverterLogicaPorta": false,
        "observacao": null,
        "equipamentoId": 1
    }

    xs < 575
    sm < 767
    md < 991
    lg < 1199
    xl > 1200

*/
