import React from 'react';
import componentQueries from 'react-component-queries';
import Routes from './Routes';

//Imports relacionado ao redux
import { Provider } from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/reduction.scss';

class App extends React.Component {
  render() {
    require('dotenv/config');
    return (
      <Provider store={store}>
        <Routes breakpoint={this.props.breakpoint} />
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
