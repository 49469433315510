import React from 'react'
import Page from 'components/Page';
import { Col, Row, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import api from 'services/api';
import MessageUtil from 'utils/alert';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import userImage from 'assets/img/arteris/user.png';
import Avatar from 'components/Avatar';

export default class UsuarioView extends React.Component {

    state = {
        redirectUsuario: false,
        user: {}
    }
    notificationSystem;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        api.get('/usuario/myself')
            .then((responseUser) => {
                this.setState({
                    user: responseUser.data
                });
                //console.log('Usuario: ', this.state.user)
            })
            .catch((error) => {
                if (this.notificationSystem !== undefined)
                    MessageUtil.showMsg(this.notificationSystem, error.response.status);

                setTimeout(() => {
                    this.setState({ redirect: true });
                }, 2000);

            })
    }

    nivelDeAcesso = () => {
        switch (this.state.user.nivelUsuario) {
            case 1:
                return "Visualizador"
            case 2:
                return "Operador"
            case 3:
                return "Administrador"
            default:
                break;
        }
    }

    redirectUser = () => {
        if (this.state.redirectUsuario)
            return <Redirect push to={`/home`} />
    }

    render() {

        return (
            <>
                {this.redirectUser()}
                <Page className="UsuarioView" title="Perfil">

                    <div className="row-fluid user-infos cyruxx">
                        <div className="span10 offset1">
                            <div className="panel panel-primary">
                                <div className="panel-body" style={{ alignContent: "center" }}>
                                    <Row className="row-fluid">
                                        
                                        <Col xl={{ size: 8, offset: 4 }} lg={{ size: 8, offset: 4 }} md={{ size: 9, offset: 3 }} sm={{ size: 10, offset: 2 }} xs={{ size: 12, offset: 0 }} >
                                            <table className="table table-condensed table-responsive table-user-information" style={{ alignContent: "center" }}>
                                                <tbody style={{ backgroundColor: "#DADADA" }}>
                                                    <tr style={{ textAlign: "center"}}>
                                                        <td colSpan="2">
                                                            <Avatar src={userImage} size={100} className="mb-2" style={{ backgroundColor: 'rgb(255,255,255)' }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nome:</td>
                                                        <td>{this.state.user.nomeUsuario}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Login:</td>
                                                        <td>{this.state.user.loginUsuario}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nível de acesso:</td>
                                                        <td>{this.nivelDeAcesso()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>E-mail</td>
                                                        <td>{this.state.user.emailUsuario}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Telefone:</td>
                                                        <td>{this.state.user.telefoneUsuario}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Habilitado:</td>
                                                        <td>{this.state.user.usuarioHabilitado === false ? "Não" : "Sim"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Receber SMS:</td>
                                                        <td>{this.state.user.receberSMSUsuario === false ? "Não" : "Sim"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Receber E-mail:</td>
                                                        <td>{this.state.user.receberEmailUsuario === false ? "Não" : "Sim"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Observação:</td>
                                                        <td>{this.state.user.observacaoUsuario === undefined ? "Vazio" : this.state.user.observacaoUsuario}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="panel-footer">
                                    <Row>
                                        <Col lg={2} md={2} sm={2} xs={3}>
                                            <Button onClick={() => { this.setState({ redirectUsuario: true }) }}>Voltar</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </>
        )
    }
}