export class DateUtil {

    static formatSecondsToTimeFormat(date) {
        // console.log('TEMPO 1 > ', date);
        var value = new Date(date * 1000)
        // console.log('TEMPO 2 > ', value);
        // console.log('TEMPO 3 > ', value.toUTCString())
        // console.log('TEMPO 4 > ', value.toUTCString().substring(17, 25))

        // HH:mm:ss
        return value.toUTCString().substring(17, 25);
    }
    static formatMinutesToTimeFormat(date) {
        // console.log('TEMPO 1 > ', date);
        var value = new Date(date * 1000 * 60)
        // console.log('TEMPO 2 > ', value);
        // console.log('TEMPO 3 > ', value.toUTCString())
        // console.log('TEMPO 4 > ', value.toUTCString().substring(17, 25))

        // HH:mm:ss
        return value.toUTCString().substring(17, 25);
    }

}