import logo from 'assets/img/arteris/ativa_solucoes.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdDevices,
  MdPeople,
  MdTrendingUp,
  MdHome,
} from 'react-icons/md';

import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

const navBarMenu = [
  { to: '/home', name: 'Home', exact: false, Icon: MdHome /*MdDashboard*/ },
  { to: '/equipament', name: 'Equipamento', exact: false, Icon: MdDevices /*MdRouter*/ },
  { to: '/user', name: 'Usuário', exact: false, Icon: MdPeople },
  { to: '/report', name: 'Relatório de Poços', exact: false, Icon: MdTrendingUp },
]

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b('red')} >
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>

          {/* Cabecalho sidebar */}
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo}
                width="100%"
                height="100%"
                className=""
                alt=""
                style={{ borderRadius: '0.75rem' }}
              />
              {/*<span className="fonts-navbar">Ativa</span>*/}
            </SourceLink>
          </Navbar>


          <Nav vertical>{/* Inicio barra navegacao */}

            {/* Map varrendo array e montando abas da aplicacao*/}
            {navBarMenu.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="fonts-navbar">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
