import React from 'react';
import { MdSyncDisabled, MdCancel, MdCheck } from 'react-icons/md';

var HttpStatus = require('http-status-codes');

export default class MessageUtil {

    static showMsg(notificationSystem, codigo = '', msg = '') {

        switch (codigo) {

            case HttpStatus.OK:

                notificationSystem.addNotification({
                    title: <MdCheck />,
                    message: msg === '' ? 'Operação realizada com sucesso.' : msg,
                    level: 'success',
                });

                break;

            case HttpStatus.NOT_FOUND:

                notificationSystem.addNotification({
                    title: <MdSyncDisabled />,
                    message: msg === '' ? 'Nenhuma informação encontrada!' : msg,
                    level: 'error',
                });

                break;
            case HttpStatus.ACCEPTED:

                notificationSystem.addNotification({
                    title: <MdCheck />,
                    message: msg === '' ? 'Operação realizada com sucesso.' : msg,
                    level: 'success',
                });

                break;

            case HttpStatus.FORBIDDEN:

                notificationSystem.addNotification({
                    title: <MdCancel />,
                    message: msg === '' ? 'Você não possui permissão suficiente.' : msg,
                    level: 'error',
                });

                break;

                case HttpStatus.BAD_REQUEST:

                notificationSystem.addNotification({
                    title: <MdCancel />,
                    message: msg === '' ? 'Dado(s) da requisição inválido(s).' : msg,
                    level: 'error',
                });

                break;

            default:

                notificationSystem.addNotification({
                    title: <MdCancel />,
                    message: msg === '' ? 'Problemas ao conectar com servidor!' : msg,
                    level: 'error',
                });

                break;
        }

    }
}