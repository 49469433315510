import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
//import Notifications from 'components/Notifications';
//import { notificationsData } from 'demos/header';
//import withBadge from 'hocs/withBadge';
import React from 'react';
import { logout } from '../../services/auth';
import { MdClearAll, MdExitToApp, /*MdNotificationsActive, MdNotificationsNone,*/ MdPersonPin } from 'react-icons/md';
import { Button, ListGroup, ListGroupItem/*, NavbarToggler*/, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import bn from 'utils/bemnames';

import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

const bem = bn.create('header');

/*const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);*/

class Header extends React.Component {
  state = {
    redirectProfile: false,
    redirectLogin: false,
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  renderRedirectProfile = () => {
    if (this.state.redirectProfile) {
      this.setState({ redirectProfile: false });
      return <Redirect to="/user/view" />
    }
  }
  renderRedirectLogin = () => {
    if (this.state.redirectLogin) {
      return <Redirect to="/login" />
    }
  }

  hadlerButtonLogout = () => {
    logout();
    this.setState({ redirectLogin: true });
    //window.location.replace('/');
  }

  hadlerButtonProfile = () => {
    this.setState({ redirectProfile: true });
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };



  render() {
    //const { isNotificationConfirmed } = this.state;

    return (

      <Navbar expand className={bem.b('bg-red')}>

        {this.renderRedirectProfile()}
        {this.renderRedirectLogin()}

        {/*  Botao Toggle Sidebar  */}
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton} className="toggleNavButton">
            <MdClearAll size={25} />
          </Button>
        </Nav>

        {/*  Botoes canto direito  */}
        <Nav navbar className={bem.e('nav-right')}>

          {/*  Notificacao  */}
          {/*
          < OverlayTrigger trigger="click" placement="bottom" overlay={

            <Popover id="popover-basic">
              <Popover.Content>
                <Notifications notificationsData={notificationsData} />
              </Popover.Content>
            </Popover>

          } rootClose={true}>
            <NavItem className="d-inline-flex" >
              <NavLink id="Popover1" className="position-relative" >
                {isNotificationConfirmed ?
                  (<MdNotificationsNone size={25} className="text-icons can-click" onClick={this.toggleNotificationPopover} />)
                  :
                  (<MdNotificationsActiveWithBadge size={25} className="text-icons can-click animated swing infinite" onClick={this.toggleNotificationPopover} />)}
              </NavLink>
            </NavItem>
          </OverlayTrigger>
                */}

          {/*  Perfil  */}
          < OverlayTrigger trigger="click" placement="bottom" overlay={

            <Popover id="popover-basic">
              <Popover.Content>
                <UserCard title={this.props.user.nomeUsuario} subtitle={this.props.user.emailUsuario} text={(this.props.user.nivelUsuario !== undefined) ? `Nível Acesso ${this.props.user.nivelUsuario}` : ""} className="border-light" >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={this.hadlerButtonProfile}>
                      <MdPersonPin /> Perfil
                    </ListGroupItem>
                    {/*<ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                      </ListGroupItem>*/}
                    <ListGroupItem tag="button" action className="border-light" onClick={this.hadlerButtonLogout}>
                      <MdExitToApp /> Sair
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </Popover.Content>
            </Popover>

          } rootClose={true}>
            <NavItem>
              <NavLink id="Popover2" >
                <Avatar onClick={this.toggleUserCardPopover} className="can-click" style={{backgroundColor: 'white'}} />
              </NavLink>
            </NavItem>
          </OverlayTrigger>
        </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.stateGlobal.user
  }
}

export default connect(mapStateToProps)(Header);
