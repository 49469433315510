
export const setEquipaments = (equipaments) => {
    return {
        type: 'SET_EQUIPAMENTO',
        equipaments
    };
}

export const setUser = (user) => {
    return {
        type: 'SET_USUARIO',
        user
    };
}