import React from 'react';
import Page from 'components/Page';
import { CustomInput, Col, Row, Button, Input, Form, FormGroup, Label,  Card, CardBody  } from 'reactstrap';
import InputMask from 'react-input-mask';
import { Redirect } from 'react-router-dom';
import MessageUtil from '../../utils/alert';
import api from '../../services/api';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

export default class UsuarioUpdate extends React.Component {

    state = {
        idUsuario: '',
        nomeUsuario: '',
        loginUsuario: '',
        senhaUsuario: '',
        nivelUsuario: 1,
        emailUsuario: 'teste@ativasolucoes.com.br',
        telefoneUsuario: '',
        usuarioHabilitado: '',
        receberEmailUsuario: false,
        receberSMSUsuario: false,
        observacaoUsuario: '',
        redirectUsuario: false
    }

    redirectUsuario = () => {
        if (this.state.redirectUsuario)
            return <Redirect push to={`/user`} />
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {

        let user = this.props.location.state.user;

        this.setState({
            idUsuario: user.idUsuario,
            nomeUsuario: user.nomeUsuario ? user.nomeUsuario : '',
            loginUsuario: user.loginUsuario ? user.loginUsuario : '',
            senhaUsuario: '',
            nivelUsuario: user.nivelUsuario ? user.nivelUsuario : 1,
            emailUsuario: user.emailUsuario ? user.emailUsuario : '',
            telefoneUsuario: user.telefoneUsuario ? user.telefoneUsuario : '',
            usuarioHabilitado: user.usuarioHabilitado ? user.usuarioHabilitado : false,
            receberEmailUsuario: user.receberEmailUsuario ? user.receberEmailUsuario : false,
            receberSMSUsuario: user.receberSMSUsuario ? user.receberSMSUsuario : false,
            observacaoUsuario: user.observacaoUsuario ? user.observacaoUsuario : '',
            redirectUsuario: user.redirectUsuario,
        })
    }

    atualizarUser = () => {

        let { redirectUsuario, ...payload } = this.state;


        api.put("/usuario/" + this.state.idUsuario, payload, { timeout: 20000 })
            .then((response) => {

                MessageUtil.showMsg(this.notificationSystem, 200)

                setTimeout(() => {
                    this.setState({ redirectUsuario: true });
                }, 1000);
            }).catch((error) => {

                if (error.response) {
                    //console.log('Erro ao editar usuário', JSON.stringify(error.response.data))

                    MessageUtil.showMsg(this.notificationSystem, error.response.status, error.response.data.status)
                }


            });
    }

    render() {

        return (

            <>
                <Page className="UsuarioUpdate" title="Atualizar Usuário" breadcrumbs={[{ name: 'Usuário', active: false }, { name: 'Atualizar', active: true }]}>

                    {/* Redirect */}
                    {this.redirectUsuario()}
                    <Card>
                        <CardBody style={{ backgroundColor: "#DADADA" }}>
                            <Form>
                                <FormGroup>
                                    <Row>
                                        <Col lg={6} md={7} sm={7} xs={12}>
                                            <Label for="nomeUsuario">Nome*:</Label>
                                            <Input type="plaintext" id="nomeUsuario" value={this.state.nomeUsuario} onChange={(event) => { this.setState({ nomeUsuario: event.target.value }) }} />
                                        </Col>

                                        <Col lg={3} md={5} sm={6} xs={12}>
                                            <Label for="loginUsuario">Login*:</Label>
                                            <Input type="plaintext" id="loginUsuario" value={this.state.loginUsuario} onChange={(event) => { this.setState({ loginUsuario: event.target.value }) }} />
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12}>
                                            <Label for="senhaUsuario">Senha:</Label>
                                            <Input type="password" id="senhaUsuario" value={this.state.senhaUsuario} onChange={(event) => { this.setState({ senhaUsuario: event.target.value }) }} />
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={10}>
                                            {/*   */}
                                            <Label for="nivelUsuario">Nível de Acesso*:</Label>
                                            <Input type="select" name="nivelUsuario" value={this.state.nivelUsuario} onChange={(event) => { this.setState({ nivelUsuario: event.target.value.substring(0, 1) }) }}>
                                                <option value="1">1 - Visualizador</option>
                                                <option value="2">2 - Operador</option>
                                                <option value="3">3 - Administrador</option>
                                            </Input>
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12}>
                                            <Label for="emailUsuario">E-mail*:</Label>
                                            <Input type="email" id="emailUsuario" value={this.state.emailUsuario} onChange={(event) => { this.setState({ emailUsuario: event.target.value }) }} />
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12}>
                                            <Label for="telefoneUsuario">Telefone*:</Label>
                                            <Input value={this.state.telefoneUsuario} type="tel" id="telefoneUsuario" mask="(99) 99999 9999"
                                                maskChar=" "
                                                tag={InputMask} onChange={(event) => { this.setState({ telefoneUsuario: event.target.value }) }} />
                                        </Col>
                                        <Col lg={3} md={4} sm={6} xs={12}>
                                            <Label for="observacaoUsuario">Observação:</Label>
                                            <Input type="plaintext" value={this.state.observacaoUsuario === undefined ? 'Vazio' : this.state.observacaoUsuario} id="observacaoUsuario" onChange={(event) => { this.setState({ observacaoUsuario: event.target.value }) }} />
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col lg={3} md={4} sm={6} xs={12}>

                                            <Label for="checkboxList">Configurações</Label>
                                            <div>
                                                <CustomInput type="checkbox" id="habilitarUsuario" label="Habilitado" checked={this.state.usuarioHabilitado} onChange={e => { this.setState({ usuarioHabilitado: e.target.checked }) }} />
                                                <CustomInput type="checkbox" id="receberEmail" label="Receber E-mail" checked={this.state.receberEmailUsuario} onChange={e => { this.setState({ receberEmailUsuario: e.target.checked }) }} />
                                                <CustomInput type="checkbox" id="receberSMS" label="Receber SMS" checked={this.state.receberSMSUsuario} onChange={e => { this.setState({ receberSMSUsuario: e.target.checked }) }} />
                                            </div>


                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Form>

                            <br />
                            <Row>
                                <Col lg={2} md={2} sm={2} xs={3}>
                                    <Button onClick={this.atualizarUser}>Salvar</Button>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={3}>
                                    <Button onClick={() => { this.setState({ redirectUsuario: true }) }}>Cancelar</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Page>


                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                        (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </>
        )
    }
}